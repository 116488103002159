*{
  margin: 0;
  padding: 0;
  
}
.App{
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #dfab0b, #d8bb00, #cdcb00, #bddb00, #a8eb12);
}
.box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.border{
  width: 360px;
  height: 745px;
  display: flex;
  /* z-index: 2; */
}
.top{
  width: 235px;
  position: absolute;
  top: 73%;
  z-index: 2;
}
.god1{
  width: 60px;
  height: 60px;
  margin-bottom: 40px;
  border: 5px solid brown;
  border-radius: 50%;
  position: absolute;
  top: 15%;
  z-index: 2;
}
.god{
  width: 240px;
  height: 180px;  
  display: flex;
  align-content: center;
  position: absolute;
  top: 75%;
  z-index: 2;
}
.name{
  position: absolute;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: medium;
  width: 250px;
  text-align: center;
  color: darkgreen;
  top: 26%;
  z-index: 2;
}
.content{
  position: absolute;
  top: 33%;
  font-size: small;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  color: maroon;
  z-index: 2;
}
.content1{
  position: absolute;
  color: red;
  top: 38%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  z-index: 2;
}
.month{
  color: maroon;
  position: absolute;
  top: 42%;
  z-index: 2;
}
.date{
  position: absolute;
  top: 44%;
  color: darkgreen;
  z-index: 2;
}
.day{
  position: absolute;
  top: 45.5%;
  color: maroon;
  left: 45%;
  z-index: 2;
  border-top: 2px solid brown;
  border-bottom: 2px solid brown;
}
.time{
  position: absolute;
  color: maroon;
  top: 45.5%;
  left: 51.5%;
  z-index: 2;
  border-top: 2px solid brown;
  border-bottom: 2px solid brown;
}
.year{
  position: absolute;
  top: 49%;
  color: maroon;
  z-index: 2;
  font-size: larger;
}
.venue{
  position: absolute;
  color: maroon;
  top: 53%;
  z-index: 2;
}
.place{
  position: absolute;
  top: 56%;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: darkgreen;
  z-index: 2;
}
.address{
  position: absolute;
  font-family: 'Times New Roman', Times, serif;
  width: 200px;
  text-align: center;
  color: maroon;
  word-wrap: break-word;
  top: 60%;
  z-index: 2;
}
.btn{
  position: absolute;
  background: white;
  width: auto;
  height: 25px;
  border: none;
  padding: 2px;
  cursor: pointer;
  top: 68%;
  z-index: 2;
}
.btn:hover{
  background-color: brown;
  color: white;
}
.a{
  background-color: white;
  text-decoration-line: none;
  position: absolute;
  background: palevioletred;
  width: auto;
  height: 25px;
  border: none;
  padding: 2px;
  cursor: pointer;
  border-radius: 5px;
  border: 0.5px solid maroon;
  color: black;
  top: 68%;
  z-index: 2;
}
.a:hover{
  background-color: brown;
  color: white;
}
@media only screen and (max-width: 425px) and (min-width: 320px){
  *{
    margin: 0;
    padding: 0;
    
  }
  .App{
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to bottom, #dfab0b, #d8bb00, #cdcb00, #bddb00, #a8eb12);

  }
  .box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
  }
  .border{
    width: 100%;
    height: 750px;
    display: flex;
    /* z-index: 2; */
  }
  .top{
    width: 235px;
    position: absolute;
    top: 80%;
    z-index: 2;
  }
  .god1{
    width: 50px;
    height: 50px;
    margin-bottom: 40px;
    border: 5px solid brown;
    border-radius: 50%;
    position: absolute;
    top: 15%;
    z-index: 2;
  }
  .god{
    width: 240px;
    height: 160px;  
    display: flex;
    align-content: center;
    position: absolute;
    top: 85%;
    z-index: 2;
  }
  .name{
    position: absolute;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: large;
    color: darkgreen;
    top: 26%;
    z-index: 2;
  }
  .content{
    position: absolute;
    top: 33%;
    font-size: small;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    color: maroon;
    z-index: 2;
  }
  .content1{
    position: absolute;
    color: red;
    top: 39%;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    z-index: 2;
  }
  .month{
    color: maroon;
    position: absolute;
    top: 42%;
    z-index: 2;
  }
  .date{
    position: absolute;
    top: 44%;
    color: darkgreen;
    z-index: 2;
  }
  .day{
    position: absolute;
    top: 45.5%;
    color: maroon;
    left: 25%;
    z-index: 2;
    border-top: 2px solid brown;
    border-bottom: 2px solid brown;
  }
  .time{
    position: absolute;
    color: maroon;
    top: 45.5%;
    left: 60%;
    z-index: 2;
    border-top: 2px solid brown;
    border-bottom: 2px solid brown;
  }
  .year{
    position: absolute;
    top: 49%;
    color: maroon;
    z-index: 2;
    font-size: larger;
  }
  .venue{
    position: absolute;
    color: maroon;
    top: 53%;
    z-index: 2;
  }
  .place{
    position: absolute;
    top: 56%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: darkgreen;
    z-index: 2;
  }
  .address{
    position: absolute;
    font-family: 'Times New Roman', Times, serif;
    width: 200px;
    text-align: center;
    color: maroon;
    word-wrap: break-word;
    top: 60%;
    z-index: 2;
  }
  .btn{
    position: absolute;
    background: white;
    width: auto;
    height: 25px;
    border: none;
    padding: 2px;
    cursor: pointer;
    top: 75%;
    z-index: 2;
  }
  .btn:hover{
    background-color: brown;
    color: white;
  }
  .a{
    background-color: white;
    text-decoration-line: none;
    position: absolute;
    background: palevioletred;
    width: auto;
    height: 25px;
    border: none;
    padding: 2px;
    cursor: pointer;
    border-radius: 5px;
    border: 0.5px solid maroon;
    color: black;
    top: 73%;
    z-index: 2;
  }
  .a:hover{
    background-color: brown;
    color: white;
  }
}